<template>

  <div>
    <div
      style="height: 95vh !important"
      class="text-center d-flex flex-1 justify-content-center align-items-center"
      v-if="IsLoading"
    >
      <b-spinner variant="success" type="grow"></b-spinner>
      Loading...
    </div>
    <div class="auth-wrapper auth-v1" v-if="!IsLoading">
    <div class="card d-flex">
      <div class="d-flex p-2 w-100">
        <div class="d-xl-flex flex-column d-none">
          <div
            class="d-flex flex-column justify-content-center align-items-center"
          >
            <b-img
              :src="appLogoImage"
              alt="logo"
              style="width: 50px"
              class="img-fluid"
            />
            <h3 class="text-center">{{ appName }}</h3>
          </div>
          <div>
            <img
              src="@/assets/images/login.png"
              style="width: 100%; max-width: 400px"
            />
            <h1 class="text-center" style="font-size: 35px; margin-top: 10px">
              Welcome back!
            </h1>
            <h3 class="text-center" style="margin-top: 20px; font-size: 13pt">
              Please login to continue
            </h3>
          </div>
        </div>

        <div
          class="d-flex flex-1 flex-column justify-content-between p-1"
          style="min-width: 400px !important"
        >
          <div class="d-flex justify-content-between">
            <h2 class="brand-text text-primary d-inline-block">Login</h2>
            <!-- <b-link variant="primary" to="/invite-mail">
              <feather-icon class="cursor-pointer" icon="ArrowRightIcon" />
              Send Invitation To get login access 
            </b-link> -->
          </div>
          <!--* form -->
          <validation-observer
            style="height: 100%"
            class="d-flex flex-1 flex-column justify-content-xl-center"
            ref="loginForm"
            #default="{ invalid }"
          >
            <b-form
              style="height: 70%"
              class="d-flex flex-1 flex-column justify-content-between"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group label-for="email" label="Email">
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="email"
                    name="login-email"
                    @click="error['email'] = ''"
                    :state="errors.length > 0 ? false : null"
                    placeholder="johndoe@example.com"
                    autofocus
                  />
                  <small class="text-danger"
                    >{{ errors[0] }}
                    {{ error["email"] ? error["email"] : "" }}</small
                  >
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group label-for="password" label="Password">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="login-password"
                      placeholder="Password"
                      @click="error['password'] = ''"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger"
                    >{{ errors[0] }}
                    {{ error["password"] ? error["password"] : "" }}</small
                  >
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                variant="primary"
                type="submit"
                block
                :disabled="invalid"
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BImg,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BSpinner
} from "bootstrap-vue";
import { required, regex } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";
export default {
  components: {
    // BSV
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BImg,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BSpinner
  },
  mixins: [togglePasswordVisibility],
  data() {
    const { appName, appLogoImage } = $themeConfig.app;
    return {
      error: {
        email: "",
        password: "",
      },
      email: "",
      password: "",
      appLogoImage,
      appName,
      // validation rules
      required,
      regex,
      IsLoading: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    async login() {
      // this.$vs.loading();
      this.IsLoading = true;
      await axios
        .post("/login", {
          email: this.email,
          password: this.password,
        })
        .then(({ data }) => {
          this.IsLoading = false;
          if (data.status == "success") {
            const { userData, accessToken, result: userAccess } = data;
            localStorage.setItem("userData", JSON.stringify(userData));
            localStorage.setItem("accessToken", JSON.stringify(accessToken));
            localStorage.setItem("userAccess", JSON.stringify(userAccess));

            this.$ability.update([
              {
                action: "manage",
                subject: "all",
              },
            ]);
            // this.$vs.loading.close();
            if (userData.role === "admin") {
              this.$router.push("admin-dash").then(() => {
                this.showToast(
                  "success",
                  "CoffeeIcon",
                  `Welcome ${userData.first_name + " " + userData.last_name}`,
                  `You have successfully logged in as ${userData.role}. Now you can start to explore!`
                );
              });
            }
            if (userData.role === "user") {
              this.$router.push("dashboard").then(() => {
                this.showToast(
                  "success",
                  "CoffeeIcon",
                  `Welcome ${userData.first_name + " " + userData.last_name}`,
                  `You have successfully logged in as ${userData.role}. Now you can start to explore!`
                );
              });
            }
          } else {
            if (data.who === "password") {
              this.error["password"] = data.message;
            }
            if (data.who === "email") {
              this.error["email"] = data.message;
            }
            this.showToast(
              "danger",
              "AlertCircleIcon",
              "Error",
              "Something went wrong!"
            );
          }
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "AlertCircleIcon",
            "Error",
            "Something went wrong!"
          );
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
